import * as React from 'react';
import * as Sentry from '@sentry/react';
import { z } from 'zod';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import dayjs from 'dayjs';
import DayJsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import DayJsLocalizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import DayJsUtcPlugin from 'dayjs/plugin/utc';
import DayJsTimezonePlugin from 'dayjs/plugin/timezone';
import DayJsDurationPlugin from 'dayjs/plugin/duration';
import DayJsQuarterOfYearPlugin from 'dayjs/plugin/quarterOfYear';
import DayJsAdvancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import { isProduction } from '@/utils';

Sentry.init({
  enabled: isProduction(),
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.zodErrorsIntegration(),
    // @see https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v6
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
});

dayjs.extend(DayJsQuarterOfYearPlugin);
dayjs.extend(DayJsRelativeTimePlugin);
dayjs.extend(DayJsLocalizedFormatPlugin);
dayjs.extend(DayJsDurationPlugin);
dayjs.extend(DayJsAdvancedFormatPlugin);
dayjs.extend(DayJsUtcPlugin);
dayjs.extend(DayJsTimezonePlugin);
dayjs.tz.setDefault('Europe/Amsterdam');

const envVariables = z.object({
  VITE_API_ENDPOINT: z.string().url(),
  VITE_GMAPS_KEY: z.string(),
  // VITE_SENTRY_DSN: z.string().url().optional().nullish(),
});

envVariables.parse(import.meta.env);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ImportMetaEnv extends z.infer<typeof envVariables> {}
}
