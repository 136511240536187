import * as React from 'react';
import { ConfigProvider, App as AntdApp } from 'antd';
import enUSLocale from 'antd/locale/en_US';
import { ProConfigProvider } from '@ant-design/pro-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import loadable from '@loadable/component';
import { AuthProvider } from './context/AuthContext';
import { AbilityProvider } from '@/context/AbilityContext';
import { OfflineDetector } from '@/features/network/OfflineDetector';
import { Empty } from '@/components/Layout/Empty';
import { LoadingPage } from '@/components/Layout/LoadingPage';
import { isProduction } from '@/utils';
import { theme } from './theme';

const MainApp = loadable(() => import('./layouts/MainApp'));
const FrontendApp = loadable(() => import('./layouts/FrontendApp'));

const App: React.FC = () => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: error => {
              if (isProduction()) {
                Sentry.captureException(error);
              } else {
                console.error(error);
              }
            },
          },
        },
      }),
  );

  return (
    <React.StrictMode>
      <AntdApp>
        <ConfigProvider theme={theme} locale={enUSLocale} renderEmpty={() => <Empty show />}>
          <ProConfigProvider>
            <OfflineDetector />
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />

              <AuthProvider>
                {({ isAuthenticated }) => (
                  <React.Suspense fallback={<LoadingPage />}>
                    {isAuthenticated ? (
                      <AbilityProvider>
                        <MainApp />
                      </AbilityProvider>
                    ) : (
                      <FrontendApp />
                    )}
                  </React.Suspense>
                )}
              </AuthProvider>
            </QueryClientProvider>
          </ProConfigProvider>
        </ConfigProvider>
      </AntdApp>
    </React.StrictMode>
  );
};

export default Sentry.withProfiler(App);
